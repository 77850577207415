/* Rectangle 1 */

.avator {
    cursor: pointer;
    margin-bottom: 10px;
}

.header {
    display: flex !important;
    justify-content: center !important;
    background: linear-gradient(90deg, rgba(29, 233, 182, 0.93) 14.64%, rgba(103, 229, 255, 0.93) 90.23%) !important;
    height: 60px !important;
    /* position: fixed !important;
    width: 100%;
    z-index: 10; */
}

.background{
    background: linear-gradient(180deg, rgba(196, 196, 196, 0.02) 0%, rgba(29, 233, 182, 0.14) 100%);
    min-height: 600px;
}



@media screen and (max-width:960px) {
    .header {
        min-height: 120px !important;
    }

    .loginBtn{
        margin:10px;
    }
}

@media screen and (max-width:480px) {
    .downloadPageImage{
        height: 350px;
        margin-bottom:-50px;

    }


    .info{
        font-size: 16px;
        margin:10px
    }
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.downloadPageImage {
    max-height: 500px;
    object-fit: contain;
}

.googlePlayImage {
    max-width: 400px !important;
    height: 55px;
    object-fit: cover;
    cursor: pointer;
    margin-right: 5px;
}

.googlePlayImage:hover {
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15) !important;
    transition: 0.25s !important;
}

.hashcodefont{
    font-size: 10px;
    margin:0px 5px 5px 5px
}

/* .hashcode:hover{
    text-decoration: underline;
} */

.loginBtn {
    border-radius: 10px !important;
    width: 150px !important;
    font-size: 16px !important;
    font-weight: bold !important;
}

.downloadbtn {
    width: 250px !important;
    height: 55px !important;
    background: black !important;
    color: white !important;
    border-radius: 10px !important;
    font-size: 24px !important;
    margin-bottom: 10px;
}

.signUpbtn:hover {
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15) !important;
    transition: 0.25s !important;
}



.footer {
    width: 100%;
    height: 130px;
    background: white !important;
}